<template lang="pug">
div.v-preview.panel
  div.v-preview__status
    v-pill(color="red") В эфире
  div.v-preview__video
    slot
</template>

<script>
import VPill from '../components/v-pill.vue'

export default {
  name: 'VPreview',
  components: {
    VPill,
  },
  props: {
    isStreaming: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.v-preview {
  height: fit-content;

  &__status {
    margin: {
      bottom: 14px;
    }
  }
}
</style>
