<template lang="pug">
div.v-pill(:style="{ '--pill-color': color }")
  slot
</template>

<script>
export default {
  name: 'VPill',
  props: {
    color: { type: String, default: '' },
  },
}
</script>

<style lang="scss">
.v-pill {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  white-space: nowrap;
  background-color: var(--pill-color, $primary-color);
  color: #ffffff;
  padding: 4px 12px;
  border-radius: 16px;
}
</style>
